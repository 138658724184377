import React from 'react';
import { PageProps, graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FacebookIcon, LinkedInIcon, TwitterIcon } from '@aviationexam/icons';
import { ActionIcon, Col, Container, Grid, Group, Text, Title } from '@aviationexam/core';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useLocation } from '@reach/router';
import { SEO } from 'components';
import { usePublicTranslation, useTranslatedRoute } from 'features/localization';
import { BLOG_PAGE } from 'config/routes';
import { BlogPostAuthor, PostNode } from 'types/post';
import { components } from '../components';
import { BlogPostAuthorThumbnail } from './components/BlogPostAuthorThumbnail';
import useStyles from './BlogPost.styles';

export interface BlogPostTemplateProps {
  title: string;
  description: string;
  content: React.ReactNode;
  featuredImage: React.ReactNode;
  socialShares: React.ReactNode;
  tags: React.ReactNode;
  author?: BlogPostAuthor;
  date?: string;
}
export function BlogPostTemplate({
  title,
  description,
  content,
  featuredImage,
  socialShares,
  tags,
  author,
  date,
}: BlogPostTemplateProps) {
  const { classes } = useStyles();
  return (
    <article>
      <Container size={1300} padding={0}>
        {featuredImage}
        <Container size="lg" className={classes.container}>
          <Grid>
            <Col span={12}>
              <Title order={1} className={classes.title}>
                {title}
              </Title>
              <Text component="p" color="gray" align="center">
                {description}
              </Text>
              {author && (
                <div className={classes.authorWrapper}>
                  <BlogPostAuthorThumbnail author={author} date={date} size="large" />
                </div>
              )}
            </Col>
            <Col span={12}>{content}</Col>
            <Col span={12} sm={6}>
              {tags}
            </Col>
            <Col span={12} sm={6}>
              {socialShares}
            </Col>
          </Grid>
        </Container>
      </Container>
    </article>
  );
}

interface DataType {
  blogPost: PostNode;
}

function BlogPost({ data: { blogPost: post } }: PageProps<DataType>) {
  const location = useLocation();
  const { t } = usePublicTranslation();
  const route = useTranslatedRoute(post.frontmatter.locale);
  const image = getImage(post.frontmatter.featuredImage);
  const { classes } = useStyles();

  return (
    <>
      <SEO
        title={post.frontmatter.postTitle[post.frontmatter.locale]}
        description={post.frontmatter.postDescription[post.frontmatter.locale]}
        lang={post.frontmatter.locale}
      >
        {post.frontmatter.urls.en && post.frontmatter.locale !== 'en' && (
          <link rel="alternate" hrefLang="en" href={post.frontmatter.urls.en} />
        )}
        {post.frontmatter.urls.de && post.frontmatter.locale !== 'de' && (
          <link rel="alternate" hrefLang="de" href={post.frontmatter.urls.de} />
        )}
      </SEO>
      <BlogPostTemplate
        title={post.frontmatter.postTitle[post.frontmatter.locale]}
        description={post.frontmatter.postDescription[post.frontmatter.locale]}
        content={
          <MDXProvider components={components}>
            <MDXRenderer>{post.parent.body}</MDXRenderer>
          </MDXProvider>
        }
        tags={
          <Group>
            {(post.frontmatter.tags ?? []).map(tag => (
              <Link
                key={tag.tagKey}
                to={`${route(BLOG_PAGE)}/${tag.tagKey}`}
                className={classes.tagLink}
                hrefLang={post.frontmatter.locale}
                title={`#${tag.tagLabel}`}
              >
                #{tag.tagLabel}
              </Link>
            ))}
          </Group>
        }
        author={post.frontmatter.author}
        date={post.frontmatter.date}
        featuredImage={
          <GatsbyImage image={image} alt={post.frontmatter.postTitle[post.frontmatter.locale]} />
        }
        socialShares={
          <Group position="right">
            <ActionIcon<'a'>
              component="a"
              color="gray"
              variant="filled"
              href={`https://twitter.com/intent/tweet?url=${location.href}`}
              target="_blank"
              rel="noreferrer"
              title={t('actions.shareWith', { what: 'Twitter' })}
              size="lg"
            >
              <TwitterIcon size={26} />
            </ActionIcon>
            <ActionIcon<'a'>
              component="a"
              color="gray"
              variant="filled"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`}
              target="_blank"
              rel="noreferrer"
              title={t('actions.shareWith', { what: 'LinkedIn' })}
              size="lg"
            >
              <LinkedInIcon size={26} />
            </ActionIcon>
            <ActionIcon<'a'>
              component="a"
              color="gray"
              variant="filled"
              href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
              target="_blank"
              rel="noreferrer"
              title={t('actions.shareWith', { what: 'Facebook' })}
              size="lg"
            >
              <FacebookIcon size={26} />
            </ActionIcon>
          </Group>
        }
      />
    </>
  );
}

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    blogPost(id: { eq: $id }) {
      id
      parent {
        ... on Mdx {
          body
        }
      }
      frontmatter {
        date
        postTitle {
          en
          de
        }
        postDescription {
          en
          de
        }
        author
        locale
        tags {
          tagKey
          tagLabel
        }
        urls {
          en
          de
        }
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: BLURRED
              formats: [WEBP, AUTO]
            )
          }
        }
      }
    }
  }
`;
