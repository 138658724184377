import React from 'react';
import {
  Button,
  Col,
  Grid,
  Title,
  Text as AviText,
  useAviTheme,
  createStyles,
} from '@aviationexam/core';
import { SEO } from 'components';
import { LocalizedLink } from 'features/localization';

interface DefaultProps {
  children: React.ReactNode;
}

interface HeadingProps extends DefaultProps {
  order: 1 | 2 | 3 | 4 | 5 | 6;
  align: 'center' | 'left' | 'right';
}
const useHeadingStyles = createStyles(theme => ({
  title: {
    textShadow:
      'rgb(11 46 115 / 3%) 0px 1.13458px 0.996212px, rgb(11 46 115 / 4%) 0px 2.72654px 2.39404px, rgb(11 46 115 / 6%) 0px 5.13384px 4.50776px, rgb(11 46 115 / 7%) 0px 9.15789px 8.04107px, rgb(11 46 115 / 8%) 0px 17.1288px 15.0399px, rgb(11 46 115 / 11%) 0px 41px 36px',
    color: theme.colors.gray[8],
    fontWeight: 300,
    margin: 'revert',
  },
}));
function Heading({ order, align, ...others }: HeadingProps) {
  const { classes } = useHeadingStyles();
  return <Title order={order} className={classes.title} style={{ textAlign: align }} {...others} />;
}

interface ParaProps extends DefaultProps {
  align?: 'block' | 'center' | 'left' | 'right';
}
function Para({ align = 'left', ...others }: ParaProps) {
  const theme = useAviTheme();
  return (
    <p
      style={{
        textAlign: align === 'block' ? 'justify' : align,
        color: theme.colors.gray[7],
        lineHeight: 1.6,
      }}
      {...others}
    />
  );
}

interface TextProps extends DefaultProps {
  align?: 'center' | 'left' | 'right';
}
function Text({ align = 'left', ...others }: TextProps) {
  const theme = useAviTheme();
  return (
    <AviText
      component="span"
      style={{
        textAlign: align,
        color: theme.colors.gray[7],
      }}
      {...others}
    />
  );
}

const useListStyles = createStyles((theme, { empty }: { empty: boolean }) => ({
  list: {
    listStyleType: empty ? 'none' : 'circle',
    paddingLeft: empty ? 0 : undefined,
    '& li': {
      color: theme.colors.gray[7],
      lineHeight: 1.6,
      margin: '5px 0',
    },
  },
}));
interface ListProps extends DefaultProps {
  empty?: boolean;
}
function List({ empty = false, ...others }: ListProps) {
  const { classes } = useListStyles({ empty });
  return <ul className={classes.list} {...others} />;
}

const useImgLabelStyles = createStyles(theme => ({
  label: {
    textTransform: 'uppercase',
    textAlign: 'center',
    display: 'block',
    margin: '10px 0',
    lineHeight: 1.6,
    color: theme.colors.gray[7],
    opacity: 0.8,
    fontWeight: 600,
  },
}));
function ImgLabel({ ...others }: DefaultProps) {
  const { classes } = useImgLabelStyles();
  return <span className={classes.label} {...others} />;
}

interface ImgProps extends React.ComponentPropsWithoutRef<'img'> {
  alt: string;
}
function Img({ alt, style, ...others }: ImgProps) {
  return (
    <img
      alt={alt}
      style={{ maxWidth: '100%', margin: '0 auto', display: 'block', ...style }}
      {...others}
    />
  );
}

export const components = {
  H1: (props: Omit<HeadingProps, 'order'>) => <Heading order={1} {...props} />,
  H2: (props: Omit<HeadingProps, 'order'>) => <Heading order={2} {...props} />,
  H3: (props: Omit<HeadingProps, 'order'>) => <Heading order={3} {...props} />,
  H4: (props: Omit<HeadingProps, 'order'>) => <Heading order={4} {...props} />,
  H5: (props: Omit<HeadingProps, 'order'>) => <Heading order={5} {...props} />,
  H6: (props: Omit<HeadingProps, 'order'>) => <Heading order={6} {...props} />,
  h1: (props: Omit<HeadingProps, 'order'>) => <Heading order={1} {...props} />,
  h2: (props: Omit<HeadingProps, 'order'>) => <Heading order={2} {...props} />,
  h3: (props: Omit<HeadingProps, 'order'>) => <Heading order={3} {...props} />,
  h4: (props: Omit<HeadingProps, 'order'>) => <Heading order={4} {...props} />,
  h5: (props: Omit<HeadingProps, 'order'>) => <Heading order={5} {...props} />,
  h6: (props: Omit<HeadingProps, 'order'>) => <Heading order={6} {...props} />,
  p: (props: ParaProps) => <Para {...props} />,
  Para: (props: ParaProps) => <Para {...props} />,
  ul: (props: DefaultProps) => <List {...props} />,
  EmptyList: (props: DefaultProps) => <List empty {...props} />,
  img: Img,
  Img,
  ImgLabel,
  a: (props: DefaultProps & React.ComponentPropsWithoutRef<'a'>) => (
    <Button<'a'> component="a" size="md" variant="link" {...props} type="button" />
  ),
  Text: (props: TextProps) => <Text {...props} />,
  Link: LocalizedLink,
  SEO,
  Row: Grid,
  Half: (props: DefaultProps) => <Col span={12} sm={6} {...props} />,
  Third: (props: DefaultProps) => <Col span={12} sm={6} md={4} {...props} />,
  Quarter: (props: DefaultProps) => <Col span={12} sm={6} md={3} {...props} />,
  Button,
};
